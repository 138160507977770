import axios from 'axios';

export async function getActiveStocks() {
    try {
        const response = await axios.get('/api/active-stocks');

        return response.data;
    } catch (e) {
        return null;
    }
}

export async function getAdvancesDeclines() {
    try {
        const response = await axios.get('/api/stock-advances-declines');

        return response.data;
    } catch (e) {
        return null;
    }
}

export async function getStocks() {
    return await axios.get('/api/stocks');
}

export async function getIndicatorValues() {
    return await axios.get('/api/indicator-values');
}

export async function getStockRangeData(stockCode, startDate, endDate) {
    return await axios.get(`/api/stocks/${stockCode}/history/${startDate}/${endDate}`);
}

export async function getStockData(stockCode, date) {
    return await axios.get(`/api/stocks/${stockCode}/history/${date}`);
}

export async function getYearReport(stockCode, year) {
    const endpoint = `/api/year-report/${stockCode}/${year}`;

    return await axios.get(endpoint);
}
